




import vue from "vue";

export default vue.extend({
  name: "Profile",
});
